import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import styles from "./styles";
import { Link } from "react-router-dom";

import {
	Typography,

	withStyles
} from "@material-ui/core";

import { Wrapper } from "../index";
import { ReactComponent as PoiIcon } from "../../assets/images/icons/3d.svg";

class Welcome extends Component {

	render () {
		const { classes } = this.props;

		return (
			<Wrapper
				className={classes.welcome}
			>
				<Typography
					className={classes.title}
					variant="h1"
				>
					{"ESPRIT DES JALLES"}
				</Typography>
				<Link
					className={classNames(classes.poi, "poi1")}
					to="decouvrez/batiment-1"
				>
					<PoiIcon
						className={classNames(classes.icon)}
					/>
				</Link>
			</Wrapper>
		);
	}

}

Welcome.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Welcome);
